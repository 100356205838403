import Layout from '../components/shared/Layout';
import PageHeader from '../components/shared/PageHeader';

const Custom404: React.FC = () => {
  return (
    <Layout>
      <PageHeader description="Whoops! We couldn't find the page you were looking for." title="Not Found" />
    </Layout>
  );
};

export default Custom404;
