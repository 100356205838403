import styled from 'styled-components';

const Container = styled.div`
  margin: 5rem 3rem 3rem 3rem;
`;

const PageDescription = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: ${({ theme }) => theme.fontSize.small};
  text-align: center;
  opacity: 0.8;
  margin: 0;
`;

const PageTitle = styled.h1`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fontFamily.bold};
  font-size: ${({ theme }) => theme.fontSize.h1};
  text-align: center;
  margin-top: 0;
  margin-bottom: 1rem;
`;

const PageDescriptionWrapper = styled.div`
  max-width: 28.2rem;
  margin: auto;
`;

export interface PageHeaderProps {
  /** Page title */
  title: string;
  /** Page description or smaller text shown below the Title */
  description?: string;
}

const PageHeader: React.FC<PageHeaderProps> = ({ title, description = null }) => {
  return (
    <Container>
      <PageTitle>{title}</PageTitle>
      <PageDescriptionWrapper>{description && <PageDescription>{description}</PageDescription>}</PageDescriptionWrapper>
    </Container>
  );
};

export default PageHeader;
